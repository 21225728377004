.football-card {
    background: url('../images/background/football_bg.jpg') no-repeat left;
    background-size: cover;
    border-radius: 20px !important;
    box-shadow: 4px 4px 5px #0000004d !important;
    border: none !important;
}
.content-position {
    padding: 10px 10px 20px 10px;
    z-index: 2;
}
.card-backdrop {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    /* background-color: rgba(0, 0, 0, 0.336); */
    background-color: rgba(0, 0, 0, 0.63);
    border-radius: 15px !important;
    z-index: 1;
}
.time {
    color: #FFF;
    text-align: center;
    text-shadow: 2px 2px 3px #000000;
    font-weight: 300;
    font-size: 13px;
    margin-bottom: 15px;
}
.goal-result {
    color: #FFF;
    text-align: center;
    text-shadow: 2px 2px 3px #000000;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    padding: 0 !important;
    border: none;
    outline: none;
    background-color: transparent;
    border: none;
}
.teamImage {
    width: 50px;
}
.team-name {
    color: #FFF;
    margin: 3px 0 0 0;
    text-shadow: 2px 2px 3px #000000;
    font-size: 13px;
}
.actionBtnShow {
    background-color: #E4A11B !important;
    color: #FFF !important;
}
.actionBtnConfirm {
    background-color: #14A44D !important;
    color: #FFF !important;
}
.actionBtnReject {
    background-color: #DC4C64 !important;
    color: #FFF !important;
}
.card-loading {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.63);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.match-type-icon-left {
    border: 1px solid #FFF;
    width: 35px;
    height: 35px;
    line-height: 27px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    top: 7px;
    left: 7px;
}
.match-type-icon-right {
    border: 1px solid #FFF;
    width: 35px;
    height: 35px;
    line-height: 27px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    top: 7px;
    right: 7px;
}