.uploadImg {
    width: 300px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
}
.uploadFile {
    display: none;
}
.uploadIcon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: #f2f2f2;
    color: #666666;
    border-radius: 50%;
    text-align: center;
    line-height: 45px;
    cursor: pointer;
}