.easy-edit-button {
    background-color: transparent;
    margin: 0 1px !important;
    padding: 0 !important;
}
.easy-edit-button i {
    font-size: 7px !important;
}
.easy-edit-component-wrapper input{
    width: 100%;
    border: none;
    outline: none;
    border-radius: 5px;
    padding-right: 60px;
    background-color: transparent !important;
}
.easy-edit-inline-wrapper {
    position: relative;
    width: 100px;
    margin: 0 auto;
}
.easy-edit-button-wrapper {
    position: absolute;
    right: 0;
}