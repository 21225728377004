.pagi-container {
    margin-top: 15px;
    display: flex !important;
    list-style-type: none !important;
    user-select: none !important;
    justify-content: flex-end !important;
    margin-bottom: 0 !important;
}
.pagi-container li {
   border: 1px solid #999 !important;
   cursor: pointer !important;
   transition: 0.1s !important;
   color: #424242 !important;
   padding: 0 !important;
   margin: 0 !important;
   font-size: 11px;
}
.pagi-container li a {
    display: block !important;
    padding: 0 !important;
    margin: 0 !important;
    font-size: 11px;
}
.pagi-container li:first-child {
    /* margin-right: 5px; */
    border-radius: 3px 0 0 3px !important;
    width: 60px !important;
    height: 35px !important;
    line-height: 32px !important;
    text-align: center !important;
}
.pagi-container li:last-child {
    /* margin-left: 5px; */
    border-radius: 0 3px 3px 0 !important;
    width: 60px !important;
    height: 35px !important;
    line-height: 32px !important;
    text-align: center !important;
    border-left: 0 !important;
}
.pagi-container li:not(:first-child):not(:last-child) {
    /* border-radius: 5px; */
    width: 40px !important;
    height: 35px !important;
    line-height: 33px !important;
    text-align: center !important;
    font-size: 14px !important;
    border-left: 0 !important;
}

.pagi-container a {
    position: static !important;
    display: block !important;
    border-radius: 0 !important;
}
.active a {
    background-color: #4099ff !important;
    border-color: #4099ff !important;
}
.active a {
    color: #FFF !important;
}