.uploadImg {
    width: 300px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
}
.uploadFile {
    display: none;
}
.uploadIcon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: #f2f2f2;
    color: #666666;
    border-radius: 50%;
    text-align: center;
    line-height: 45px;
    cursor: pointer;
}
.previewDetailImage {
    width: 100px;
    height: 100px;
    border-radius: 7px;
    display: block;
    padding: 5px;
    border: 1px solid rgba(57, 3, 3, 0.228);
    margin-right: 7px;
}
.previewDetailImage img{
    width: 100%;
    height: 100%;
    border-radius: 7px;
}
.histPara {
    font-size: 15px;
    text-align: justify;
    margin-bottom: 0px;
    width: 100%;
    word-break: break-all;
}
.commentContainer {
    max-height: 50vh;
    overflow: hidden auto;
    position: relative;
    margin-bottom: 10px;
}
.commentNote {
    padding: 0px 15px;
    margin-bottom: 10px;
}
.userImg {
    border-radius: 50%;
    height: 35px;
    width: 35px;
}
.messageContainer {
    background-color: #0084ff;
    border-radius: 20px;
    margin-left: 10px;
    padding: 10px 15px;
    /* width: 100%; */
}
.userName {
    color: #3f8bef;
    font-size: 14px;
    font-weight: bolder;
    margin-bottom: 0;
}
.messageTime {
    align-items: center;
    color: #afafaf;
    display: flex;
    font-size: 12px;
    margin-bottom: 0;
}
.userComment {
    font-size: 12px;
    margin: 5px 0 0;
    text-align: justify;
}

.adminComment {
    font-size: 12px;
    margin: 0;
    text-align: justify;
    width: 100%;
    word-wrap: break-word;
    color: #FFF;
}
.messageSize {
    word-wrap: break-word;
    /* width: 100%; */
    max-width: 70%;
}
.adminMessageTime {
    align-items: center;
    color: #afafaf;
    display: flex;
    font-size: 12px;
    margin-bottom: 0;
    justify-content: flex-end;
}