.switch-container {
  /* width: 51px;
    height: 31px; */
  width: 40px;
  height: 20px;
  position: relative;
}

/* Hide default HTML checkbox */
.checkbox {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.switch {
  width: 100%;
  height: 100%;
  display: block;
  background-color: #ed4c78;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  margin-bottom: 0;
}

/* The slider */
.slider {
  width: 16px;
  height: 16px;
  position: absolute;
  left: calc(50% - 16px / 2 - 10px);
  top: calc(50% - 16px / 2);
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.checkbox:checked + .switch {
  background-color: #14a44d;
}

.checkbox:checked + .switch .slider {
  left: calc(50% - 16px / 2 + 10px);
  top: calc(50% - 16px / 2);
}
