.maintain-container {
    background: rgb(103, 66, 230);
    background: linear-gradient(
      135deg,
      rgba(103, 66, 230, 1) 35%,
      rgba(114, 68, 237, 1) 100%
    );
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
      sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
  }
  
  .error-image {
    max-width: 720px;
    width: 90%;
    margin: auto;
    text-align: center;
  }
  
  .error-image h1 {
    font-size: 120px;
    margin: 48px auto 20px;
  }
  
  .error-msg-container {
    margin: 18px auto 30px auto;
    max-width: 800px;
    width: 80%;
    text-align: center;
  }
  
  .error-msg-container h1 {
    font-size: 40px;
    max-width: 560px;
    margin: auto auto 48px;
    color: #FFF;
  }

  .maintenance_text {
    font-size: 18px;
    color: #8cfb9c;
  }
  